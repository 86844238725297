import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import '../styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/Layout';

const PodCasts = () => {
  const data = useStaticQuery(
    graphql`
      query {
        contentfulPodcastPage {
          title
          description {
            description
          }
        }

        allContentfulPodcast(sort: { fields: date, order: DESC }) {
          nodes {
            slug
            title
            date(formatString: "DD MMMM YYYY")
            episodeNumber
            thumbnailImage {
              file {
                url
              }
            }
            link
            description
          }
        }
      }
    `
  );

  return (
    <div className='podcast'>
      <Layout isWithoutHero={true}>
        <div
          style={{
            marginTop: '150px',
            marginBottom: '50px',
            minHeight: '80vh',
          }}
        >
          <div className='title' style={{ marginBottom: '30px', color: "#000" }}>
            Digital DNA - Fireside Chat with Ankur Joshi
          </div>
          <div
            className='subtitle'
            style={{ marginBottom: '10px', color: "#000"}}
          >
            Listen to our Podcasts
          </div>
          <section className='podcast-content'>
            <div className='container'>
              <div className='podcast-row'>
                <div className='col-lg-8 col-12 podcast-column'>
                  <div className='episode-content'>
                    <div className='more-episode'>
                      <h4 className='section-title'>Recent Episodes</h4>
                      {Object.keys(data.allContentfulPodcast.nodes).map(i => {
                        return (
                          <div className='related-episode'>
                            <img
                              className='episode--thumbnail'
                              src={
                                data.allContentfulPodcast.nodes[i]
                                  .thumbnailImage.file.url
                              }
                              alt='image'
                            />
                            <div className='episode--info'>
                              <div className='title-prod podcast-tab-title' style={{fontSize: "20px", height: "auto", color: "#002e4a"}}>

                                {data.allContentfulPodcast.nodes[i].title}{' '}
                              </div>
                              <p className='episode--duration'>
                                {data.allContentfulPodcast.nodes[i].date}
                              </p>
                            </div>
                            <a
                              href={`/podcast/${data.allContentfulPodcast.nodes[i].slug}`}
                              name='Best of This Week: March 11th'
                              target='_self'
                              className='related-episode-link'
                            >
                              <div class='listen-now'>
                                <span class='listen-now--button-text'>
                                  Listen Now
                                </span>
                                <svg
                                  stroke='currentColor'
                                  fill='currentColor'
                                  stroke-width='0'
                                  viewBox='0 0 448 512'
                                  class='play-icon'
                                  height='1em'
                                  width='1em'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z'></path>
                                </svg>
                              </div>
                            </a>                      
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-12 podcast-column'>
                  <div className='podcast-about'>
                    <h4 className='section-title'>{data.contentfulPodcastPage.title}</h4>
                    <div className='podcast-about-inner'>
                      <div>
                      {data.contentfulPodcastPage.description.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </div>
  );
};

export default PodCasts;
